import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768; 
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+q7CVcL2jitY3OGM1", "_blank");
  };

  return (
    <div className="App" onClick={isMobileDevice() ? openTelegram : null}>
      <div class="visual">
        <img class="avatar" src="ANIL.jpg" alt="anil" />
      </div>
      <div className="name">
        ANIL CHAUDHARY
      </div>
      <div>
        <button class="btn">
          <span>
            <i
              class="fa fa-hand-o-right"
              style={{ fontSize: "1.6rem", color: "white" }}
            ></i>
            &nbsp;JOIN TELEGRAM CHANNEL&nbsp;
            <i
              class="fa fa-hand-o-left"
              style={{ fontSize: "1.6rem", color: "white" }}
            ></i>
          </span>
        </button>
      </div>
      <p>🔰 𝗜𝗖𝗖 𝗘𝗟𝗜𝗧𝗘 𝗣𝗔𝗡𝗘𝗟𝗜𝗦𝗧 𝗨𝗠𝗣𝗜𝗥𝗘｡ 🎖️</p>
      <p>🔰 𝗜𝗡𝗗𝗜𝗔'𝗦 𝗠𝗢𝗦𝗧 𝗘𝗫𝗣𝗘𝗡𝗦𝗜𝗩𝗘 𝗧𝗜𝗣𝗣𝗘𝗥. 🎖️</p>
      <div style={{ height: "1.2rem" }} />
      <p>🔰 𝗔𝗟𝗟 𝗙𝗜𝗫𝗫 𝗥𝗘𝗣𝗢𝗥𝗧𝗦 𝗖𝗢𝗠𝗘 𝗛𝗘𝗥𝗘｡ 🎖️</p>
      <p>🔰 𝗔𝗟𝗟 𝗧𝗢𝗦𝗦 - 𝗠𝗔𝗧𝗖𝗛 - 𝗦𝗘𝗦𝗦𝗜𝗢𝗡𝗦 𝗔𝗩𝗔𝗜𝗟𝗔𝗕𝗟𝗘. 🎖️</p>
      <p>🔰 𝗜𝗣𝗟 - 𝗣𝗦𝗟 - 𝗕𝗕𝗟 - 𝗔𝗦𝗜𝗔 𝗖𝗨𝗣 - 𝗪𝗢𝗥𝗟𝗗 𝗖𝗨𝗣🎖️</p>

      <div className="item button-pulse">
        <button className="button">
          <img src="logo.webp" alt="Logo" />
          <div className="join">&nbsp;JOIN TELEGRAM</div>
        </button>
      </div>
      <div className="accurate">👆 𝗕𝗘𝗦𝗧 𝗔𝗖𝗖𝗨𝗥𝗔𝗖𝗬 𝗜𝗡 𝗧𝟮𝟬 𝗟𝗘𝗔𝗚𝗨𝗘𝗦 . 👆</div>
    </div>
  );
}

export default App;